import React from "react"
import { graphql } from "gatsby"
import { PaginatedList } from "react-paginated-list"
import Seo from "../components/Seo"
import Layout from "../components/layout/Layout"
import Event from "../components/Event"
import HeaderPlain from "../components/HeaderPlain"
import GetUpdates from "../components/GetUpdates"

const EventsPage = ({ data }) => {
  const upcomingEvents = data.allContentfulEvent.nodes
    .filter(node => node.difference <= 0).reverse()
  const pastEvents = data.allContentfulEvent.nodes
    .filter(node => node.difference > 0)
  return (
    <>
      <Seo title="Events" bodyClass="page__events presentation" />
      <Layout>
        <section className="layout__main s__white events">
          <div className="nav__spacer"></div>
          <HeaderPlain title="Events" />
          <div className="u__container u__vspacing">
            <div className="row u__spacing team py-0">
              <div className="col-sm-9 col-lg-8">
                <div className="events__intro-wrapper">
                  <div className="events__description">
                    <p>The Policy Lab at Brown University hosted a variety of events from 2017-2023, including speaker series and technical workshops.</p>
                     <p>Below is a sampling of events, including a few with online recordings:</p>
                  </div>
                </div>
                <div id="upcoming" className="events__upcoming-wrapper">
                  {(upcomingEvents.length === 0) ? (
                    <div className="events__description">
                      <h2 className="events__title">There are no upcoming events scheduled at this time.</h2>
                    </div>
                  ) : (
                    <>
                      <h2>Upcoming Events</h2>
                      {upcomingEvents.map(node => {
                        return (
                          <Event
                            key={node.contentful_id}
                            difference={node.difference}
                            title={node.title}
                            speaker={node.speaker}
                            unformattedDate={node.unformattedDate}
                            formattedDate={node.formattedDate}
                            time={node.time}
                            placeName={node.placeName}
                            address={node.address}
                            map={node.locationMapUrl}
                            registrationLink={node.registration.link}
                            registrationText={node.registration.displayText}
                            body={node.body}
                          />
                        )
                      })}
                    </>
                  )}
                </div>
                <div id="past" className="events__past-wrapper">
                  <h2>Past Events</h2>
                  <PaginatedList
                    list={pastEvents}
                    itemsPerPage={10}
                    renderList={list => (
                      <>
                        {list.map(node => {
                          return (
                            <Event
                              key={node.contentful_id}
                              difference={node.difference}
                              title={node.title}
                              speaker={node.speaker}
                              unformattedDate={node.unformattedDate}
                              formattedDate={node.formattedDate}
                              time={node.time}
                              placeName={node.placeName}
                              address={node.address}
                              body={node.body}
                            />
                          );
                        })}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <GetUpdates sectionClass="s__light" />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  {
    allContentfulEvent(sort: {fields: startDate, order: DESC}) {
      nodes {
        body {
          childMarkdownRemark {
            html
          }
        }
        contentful_id
        registration {
          displayText
          link
        }
        placeName
        address
        locationMapUrl
        speaker
        unformattedDate: startDate
        formattedDate: startDate(formatString: "dddd, MMMM DD, YYYY")
        difference: startDate(difference: "days")
        title
        time
      }
    }
  }
`

export default EventsPage
