import React from "react"

export default function Event({ difference, registrationLink, registrationText, title, speaker, unformattedDate, formattedDate, time, placeName, address, map, body }) {
  const upcoming = difference <= 0
  const past = difference > 0
  return (
    <div className="events__description">
      <h3 className="events__title">
        {(registrationLink && upcoming) &&
          <a href={registrationLink} className="events__link" target="_blank" rel="nofollow noreferrer">
            {title}
          </a>
        }
        {past &&
          title
        }
      </h3>
      {speaker &&
        <p className="h5 alt-bold events__speaker">{speaker}</p>
      }
      <p className="h5 events__date">
        <time dateTime={unformattedDate}>{formattedDate}, {time}</time>
      </p>
      <div className="h5 events__location">
        <p>
          {placeName &&
            <>
              <span>{placeName}</span>
              <br />
            </>
          }
          {address &&
            <>
              <span>{address}</span>
            </>
          }
        </p>
      </div>
      {(map && upcoming) &&
        <p className="h5 alt-bold events__map-link">
          <a href={map} target="_blank" rel="nofollow noreferrer">
            <span className="events__map__text">Map &rarr;</span>
          </a>
        </p>
      }
      {(registrationLink && upcoming) &&
        <p className="h5 alt-bold events__register-link">
          <a href={registrationLink} target="_blank" className="events__register__link" rel="nofollow noreferrer">
            {registrationText} &rarr;
          </a>
        </p>
      }
      {(body !== null) &&
        <div className="events__body" dangerouslySetInnerHTML={{__html: body.childMarkdownRemark.html}}></div>
      }
    </div>
  )
}
