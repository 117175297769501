import React from "react"

export default function HeaderPlain(props) {
  return (
    <header className="header-plain__header">
      <div className="row u__container u__spacing u__vspacing">
        <div className="header-plain__title-wrapper">
          {props.title &&
            <h1 className="header-plain__title">{props.title}</h1>
          }
          {props.subtitle &&
            <span className="header-plain__subtitle h5">{props.subtitle}</span>
          }
        </div>
      </div>
    </header>
  )
}
